import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HiddenPinComponent } from './hidden-pin.component';

@NgModule({
  declarations: [
    HiddenPinComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
  exports: [
    HiddenPinComponent
  ]
})
export class HiddenPinModule { }
