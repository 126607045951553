import { DateTime } from 'luxon';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-expiration-date',
  templateUrl: './expiration-date.component.html',
  styleUrls: ['./expiration-date.component.scss']
})
export class ExpirationDateComponent implements OnInit {

  @Input()
  public date: string;

  public relativeStr: string;


  constructor() { }

  ngOnInit(): void {
    this.relativeStr = DateTime.fromISO(this.date).toRelativeCalendar() ?? '';
  }

}
