import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeUnit'
})
export class TimeUnitPipe implements PipeTransform {

  transform(value: number, timeUnit: string): string {
    var returnString = 'Cada';

    switch (timeUnit) {
      case 'Months':
        if (value == 1) {
          returnString = returnString + ' mes';
        } else {
          returnString = returnString + ' ' + value + ' meses';
        }
        break;
      case 'Years':
        if (value == 1) {
          returnString = returnString + ' año';
        } else {
          returnString = returnString + ' ' + value + ' años';
        }
        break;
      case 'Weeks':
        if (value == 1) {
          returnString = returnString + ' semana';
        } else {
          returnString = returnString + ' ' + value + ' semanas';
        }
        break;
    }
    return returnString;
  }

}
