import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  getToken(tokenType: TokenService.TokenType = TokenService.TokenType.JwtToken): string | null {
    return localStorage.getItem(this.GetTokenKey(tokenType));
  }

  setToken(tokenType: TokenService.TokenType = TokenService.TokenType.JwtToken, token: string) {
    localStorage.setItem(this.GetTokenKey(tokenType), token);
  }

  removeToken(tokenType: TokenService.TokenType = TokenService.TokenType.JwtToken) {
    localStorage.removeItem(this.GetTokenKey(tokenType));
  }

  isTokenValid() {
    return (this.getTokenData() && !this.isTokenExpired());
  }

  getTokenData(): any {
    let token = this.getToken(TokenService.TokenType.JwtToken);
    return token ? helper.decodeToken(token) : null;
  }

  isTokenExpired(): boolean {
    let token = this.getToken(TokenService.TokenType.JwtToken);
    return token ? helper.isTokenExpired(token) : true;
  }

  private GetTokenKey(tokenType: TokenService.TokenType){
    switch (tokenType) {
      case TokenService.TokenType.JwtToken:
        return 'jwt_token';
      case TokenService.TokenType.JwtRefreshToken:
        return 'jwt_refresh_token';
    }
  } 
}

export namespace TokenService
{
  export enum TokenType {
    JwtToken,
    JwtRefreshToken
  };
}
