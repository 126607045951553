import { HiddenPinModule } from './../../../../components/hidden-pin/hidden-pin.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { ComponentsModule } from 'src/app/components/components.module';
import { ApplicationPipesModule } from 'src/app/pipes/application-pipes.module';
import { StockDetailComponent } from './stock-detail.component';

@NgModule({
  declarations: [
    StockDetailComponent
  ],
  imports: [
    CommonModule,
    
    FormsModule,
    ReactiveFormsModule,
    
    TranslateModule,
    ComponentsModule,
    ApplicationPipesModule,

    MatIconModule,
    MatButtonModule,
    MatCardModule,
    HiddenPinModule
  ],
  exports: [
    StockDetailComponent
  ]
})
export class StockDetailModule { }
