import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      return new Promise((resolve, reject) => {
        this.auth.authenticationState.subscribe(isAuthenticated =>{
          if(isAuthenticated !== null){

            if(!isAuthenticated){
              // not logged in so redirect to login page with the return url
              console.log(state.url);
              if(state.url == '/reset-pass'){
                this.router.navigate(['/reset-pass']);
              }
              else{
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
              }
              resolve(false);
              return;
            }

            // authorised so return true
            resolve(true);
            return;
          }
        });
      });
  }

}
