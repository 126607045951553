import { CenterDTO } from './../DTO/CenterDTO';
import { ClientDTO } from '../DTO/ClientDTO';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  public static CLIENT = 'CLIENT';
  public static CENTER = 'CENTER';

  public client = new BehaviorSubject<ClientDTO | null>(null);
  public center = new BehaviorSubject<CenterDTO | null>(null);

  constructor() {
    this.refreshSubject(StorageService.CLIENT, this.client);
    this.refreshSubject(StorageService.CENTER, this.center);
   }

  public setClient(obj: ClientDTO | null) {
    this.client.next(obj);
    this.set(StorageService.CLIENT, obj);
  }

  public setCenter(obj: CenterDTO | null) {
    this.center.next(obj);
    this.set(StorageService.CENTER, obj);
  }

  //#region PRIVATE

  private set<T>(key: string, obj: T) {
    if(obj){
      localStorage.setItem(key, JSON.stringify(obj));
    }else{
      this.remove(StorageService.CENTER);
    }
  }

  private get<T>(key: string): T | null {
    const json = localStorage.getItem(key);
    if(json){
      return JSON.parse(json) as T;
    } else return null;
  }

  private remove<T>(key: string) {
    localStorage.removeItem(key);
  }

  private refreshSubject<T>(key: string, sub: Subject<T | null>){
    const val = this.get<T>(key);
    sub.next(val);
  }

  //#endregion
}
