import { Toast } from "./Toast";

export class Errors {

    public static showGenericError() {
        return Toast.fire({
            title: '¡Vaya!',
            text: 'Se ha producido un error',
            icon: 'error',
        });
    }
}