import { from, Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ProductStockDTO } from 'src/app/DTO/ProductStockDTO';
import { MatPaginator } from '@angular/material/paginator';
import { StocksFiltersRequestDTO } from 'src/app/DTO/Requests/StocksFiltersRequestDTO';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockDetailComponent } from './stock-detail/stock-detail.component';
import { ClientDTO } from 'src/app/DTO/ClientDTO';
import { StorageService } from 'src/app/services/storage.service';
import { CenterDTO } from 'src/app/DTO/CenterDTO';
@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StocksComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'center',
    'lote',
    'productName',
    'status',
    'expirationDatePrimary',
    'expirationDateSecondary',
    'consumed',
    'labelsCount',
  ];

  dataSource = new MatTableDataSource<ProductStockDTO>();

  public centers: CenterDTO[] = [];

  public firstSearchDone = false;
  public isLoading = false;
  public exportLoading = false;

  public filters = new StocksFiltersRequestDTO();

  public filterBoolOptions = [
    { label: 'SI', value: true },
    { label: 'NO', value: false }
  ]

  public client: ClientDTO;
  private sub: Subscription;

  constructor(
    private api: ApiService,
    private modalService: NgbModal,
    private storage: StorageService
  ) {
    
  }

  ngOnInit(): void {
    
    this.sub = this.storage.client.subscribe((res) => {
      if(res){
        this.client = res;
        this.api.getCenters(res.id).then((res) => {
          this.centers = res;
          this.filters.centerIds = [];
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  refreshData(){
    this.firstSearchDone = true;
    this.isLoading = true;

    this.api.getStocksByFilters(this.client.id, this.filters).then(data => {
      this.dataSource.data = data;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isLoading = false;
    });
  }

  openStock(el: ProductStockDTO){
    this.openModal(el);
  }

  exportXLS(){
    this.exportLoading = true;
    this.api.downloadFilePost(`/Products/StocksByFilters/xls?clientId=${this.client.id}`, this.filters, 'product-stocks-export').finally(() => {
      this.exportLoading = false;
    })
  }

  public openModal(product?: ProductStockDTO) {
    const modalRef = this.modalService.open(StockDetailComponent); 
    modalRef.componentInstance.id = product?.id;

    modalRef.result.then((result) => {
      
    }, (reason) => {
      console.log(reason);
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
}
