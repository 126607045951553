export class StocksFiltersRequestDTO {
    product: string | null;
    lote: string | null;
    user: string | null;
    expirationFrom: string | null;
    expirationTo: string | null;
    receptionFrom: string | null;
    receptionTo: string | null;
    consumed: boolean | null;
    elaborated: boolean | null;
    centerIds: number[] | null;
}