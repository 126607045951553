import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './services/language/language.service';
import { ApiService } from 'src/app/services/api.service';
import { ClientDTO } from './DTO/ClientDTO';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';
import { StorageService } from './services/storage.service';
import { environment } from 'src/environments/environment';
import { TokenService } from './services/token.service';
import { Fancybox } from '@fancyapps/ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isLogged: boolean = false;

  selectedLang: string;

  clients: ClientDTO[] = [];
  selectedClient: ClientDTO | null ;
  selectedClientId: number;

  public env = environment;

 public role = "";

  constructor(
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private router: Router,
    private api: ApiService,
    private storage: StorageService,
    private translate: TranslateService,
    private language: LanguageService,
    private tokenService: TokenService
    ) {
      this.auth.authenticationState.subscribe({
        next: (res) => {
          this.isLogged = res ?? false;
          if(res){
            this.refreshClient();
            var tokenData = this.tokenService.getTokenData();
            //console.log(tokenData);
            this.role = tokenData.role;
          } else {
            this.role = "";
          }
        }
      });

      this.auth.checkToken();

      this.storage.client.subscribe((res) => {
        this.selectedClient = res;
        this.selectedClientId = res?.id || 0;
      });

      Fancybox.bind("[data-fancybox]", {
        // Options
      });
  }

  ngOnInit() {
   this.selectedLang = this.translate.defaultLang;
  }

  refreshClient(){
    this.api.getClients().then((res) => {
      this.clients = res;
      let currentClient = this.storage.client.value;

      let findCurrentClient = null;
      if (currentClient){
        findCurrentClient = res.find(x => x.id == currentClient?.id);
      }

      if(findCurrentClient != null) {
        this.storage.setClient(findCurrentClient);
      } else if(res && res.length){
        this.storage.setClient(res[0]);
      } else {
        this.storage.setClient(null);
      }
    });
  }

  onClientChanges(){
    let client = this.clients.find(x => x.id == this.selectedClientId);
    if(client){
      this.storage.setClient(client);
    }
  }

  onLanguageChanges(){
    this.language.setLanguage(this.selectedLang);
  }

  logout(){
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  displayFn(user: any): string {
    return user && user.name ? user.name : '';
  }
}
