import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService
  ) { }

  setLanguage(lng: string) {
    this.translate.use(lng);
    localStorage.setItem('locale', lng);
  }
  
}
