import { CenterDTO } from './../DTO/CenterDTO';
import { UserDTO } from './../DTO/UserDTO';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL } from 'src/environments/environment';
import { ProductDTO } from '../DTO/ProductDTO';
import { ProductStockDTO } from '../DTO/ProductStockDTO';
import { LabelDTO } from '../DTO/LabelDTO';
import { CenterTypologyDTO } from '../DTO/CenterTypologyDTO';
import {EquipmentDTO} from "../DTO/EquipmentDTO";
import {SupplierDTO} from "../DTO/SupplierDTO";
import {Observable} from "rxjs";
import {ChangePasswordDTO} from "../DTO/ChangePasswordDTO";
import {MaintenanceRevisionConfigurationDTO} from "../DTO/MaintenanceRevisionConfigurationDTO";
import {MaintenanceActionDTO} from "../DTO/MaintenanceActionDTO";
import {MaintenanceCommentDTO} from "../DTO/MaintenanceCommentDTO";
import {CentersAndDateDTO} from "../DTO/CentersAndDateDTO";
import { Errors } from '../shared/helpers/Errors';
import { ActionDTO } from '../DTO/ActionDTO';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public get(url: string) {
    const p = new Promise<any>((resolve, reject) => {
      this.http.get(url)
      .subscribe({
        next: data => {
          //console.log(['GET RESPONSE', url, 'Response:', data]);
          this.CheckResponse(url, data, resolve, reject);
        },
        error: (err)=> {
          this.errorHandler(err);
          reject(err);
        }
      });
    });

    return p;
  }

  public put(url: string, data: any) {
    console.log(['PUT', url]);

    const p = new Promise<any>((resolve, reject) => {
      this.http.put(url, data)
      .subscribe({
        next: (data) => {
          console.log(['PUT RESPONSE', url, 'Response:', data]);
          this.CheckResponse(url, data, resolve, reject);
        },
        error: (err) => {
          this.errorHandler(err);
          reject(err);
        }
      });
    });

    return p;
  }

  public post(url: string, data: any) {
    console.log(['POST', url, data]);

    const p = new Promise<any>((resolve, reject) => {
      this.http.post(url, data)
      .subscribe({
        next: (responseData) => {
          console.log(['POST RESPONSE', url, 'Response:', responseData]);
          this.CheckResponse(url, responseData, resolve, reject);
        },
        error: (err) => {
          this.errorHandler(err);
          reject(err);
        }
      });
    });

    return p;
  }

  public delete(url: string) {
    console.log(['DELETE', url]);

    const p = new Promise<any>((resolve, reject) => {
      this.http.delete(url)
      .subscribe({
        next: (data) => {
          console.log(['DELETE RESPONSE', url, 'Response:', data]);
          this.CheckResponse(url, data, resolve, reject);
        },
        error: (err) => {
          this.errorHandler(err);
          reject(err);
        }
      });
    });

    return p;
  }

  private CheckResponse(url: string, res: any, resolveHandler: (value?: any) => void, rejectHandler: (value?: any) => void) {
    resolveHandler(res);
  }

  private async errorHandler(err: HttpErrorResponse) {
    console.error(err);
    //this.events.publish('ERROR', 'Error de conexión', Date.now());
    switch (err.status) {
      case 401:
      case 403:
        //this.auth.refreshToken();
        break;
      default:
        /*const t = await this.translate.get(['Oops', 'Errors.GENERIC', 'ACCEPT']).toPromise();
        Swal.fire({
          title: t.Oops,
          text: t['Errors.GENERIC'],
          icon: 'error',
          confirmButtonText: t.ACCEPT,
        });*/
    }
  }

  //#region Clients

  getClients(): Promise<any[]>{
    return this.get(`${API_URL}/centers/clients`);
  }

  getCenters(clientId: number): Promise<CenterDTO[]>{
    return this.get(`${API_URL}/centers?clientId=${clientId}`);
  }

  //#endregion

  //#region Centers

  getCenterById(id: number): Promise<CenterDTO>{
    return this.get(`${API_URL}/centers/${id}`);
  }

  putCenter(center: CenterDTO){
    return this.put(`${API_URL}/centers`, center);
  }

  postCenter(center: CenterDTO){
    return this.post(`${API_URL}/centers`, center);
  }

  getCenterTypologies(clientId: number): Promise<CenterTypologyDTO[]>{
    return this.get(`${API_URL}/centers/typologies?clientId=${clientId}`);
  }
  getCenterTypologyById(id: number): Promise<CenterDTO>{
    return this.get(`${API_URL}/centers/typologies/${id}`);
  }
  postCenterTypology(typology: CenterTypologyDTO, clientId: number){
    return this.post(`${API_URL}/centers/typologies?clientId=${clientId}`, typology);
  }
  putCenterTypology(typology: CenterTypologyDTO, clientId: number){
    return this.put(`${API_URL}/centers/typologies?clientId=${clientId}`, typology);
  }

  //#endregion

  //#region Users

  getUsers(clientId: number, centerId: number): Promise<UserDTO[]>{
    return this.get(`${API_URL}/users?clientId=${clientId}&centerId=${centerId}`);
  }
  resetPasswordValidateToken(token: any, userName : any): Promise<any>{
    const params = new HttpParams()
      .set('token', token)
      .set('userName', userName);
    return this.get(`${API_URL}/users/reset-password-validate-token?${params.toString()}`);
  }

  resetPassword(newPassword: ChangePasswordDTO): Promise<any>{
    return this.put(`${API_URL}/users/reset-password`, newPassword);
  }


  getUserById(id: number): Promise<UserDTO> {
    return this.get(`${API_URL}/users/${id}`);
  }
  public completeRegistration(user:UserDTO): Promise<any> {
    return this.post(`${API_URL}/Users/completeRegistrationInvitation`, user);
  }
  postUser(clientId: number,user: UserDTO){
    return this.post(`${API_URL}/users?clientId=${clientId}`, user);
  }

  putUser(user: UserDTO){
    return this.put(`${API_URL}/users`, user);
  }

  getRoles(): Promise<string[]>{
    return this.get(`${API_URL}/users/roles`);
  }

  nfcIsAvailability(nfcTag: string): Promise<boolean>{
    return this.get(`${API_URL}/auth/nfcIsAvailability?nfcTag=${nfcTag}`);
  }

  //#endregion

  //#region Products

  getProducts(clientId: number): Promise<ProductDTO[]>{
    return this.get(`${API_URL}/products?clientId=${clientId}&active=`);
  }

  getProductById(id: number){
    return this.get(`${API_URL}/products/${id}`);
  }

  postProductByCenter(centerId: number, product: ProductDTO){
    return this.post(`${API_URL}/products/?centerId=${centerId}`, product);
  }

  postProductByClient(product: ProductDTO, clientId: number){
    return this.post(`${API_URL}/products/?clientId=${clientId}`, product);
  }

  putProduct(product: ProductDTO){
    return this.put(`${API_URL}/products`, product);
  }

  getStocksByFilters(clientId: number | null, filters:any): Promise<ProductStockDTO[]>{
    return this.post(`${API_URL}/products/stocksByFilters?clientId=${clientId??''}`, filters);
  }

  getStockById(id: number): Promise<ProductStockDTO>{
    return this.get(`${API_URL}/products/stocks/${id}`);
  }

  getLabelsByStockUuid(stockUuid: string): Promise<LabelDTO[]>{
    return this.get(`${API_URL}/labels/${stockUuid}`);
  }

  //#endregion

  //#region ProductsCategories

  getProductsCategories(): Promise<any[]>{
    return this.get(`${API_URL}/products/categories`);
  }

  getProductsExpirationCategories(clientId: number): Promise<any[]>{
    return this.get(`${API_URL}/products/expirationCategories?clientId=${clientId}`);
  }

  //#endregion

  //#region Suppliers

  getSuppliers(clientId: number): Promise<any[]>{
    return this.get(`${API_URL}/${clientId}/suppliers`);
  }

  getSupplier(clientId: number, id: number): Promise<any[]>{
    return this.get(`${API_URL}/${clientId}/suppliers/${id}`);
  }
  postSupliers(clientId: number, suplier: SupplierDTO): Promise<any>{
    return this.post(`${API_URL}/${clientId}/suppliers`, suplier);
  }
  putSupliers(clientId: number, suplier: SupplierDTO): Promise<any>{
    return this.put(`${API_URL}/${clientId}/suppliers`, suplier);
  }
  deleteUser(id: number){
    return this.get(`${API_URL}/suppliers/deleteUser?userId=${id}`);
  }
  getUsersBySuplierId( id: number): Promise<any[]>{
    return this.get(`${API_URL}/suppliers/getUsers?supplierId=${id}`);
  }
  postUserSuplier(user: UserDTO): Promise<any>{
    return this.post(`${API_URL}/suppliers/createUser`, user);
  }


  //#endregion
  //#region Equipment
  getEquipments(clientId: number, centerIds: number[]): Promise<EquipmentDTO[]>{
    return this.post(`${API_URL}/${clientId}/Equipments/getByCenters`, centerIds);
  }

  getRevisionsByMonthAndcenters(centersAndData: CentersAndDateDTO): Promise<MaintenanceActionDTO[]>{
    return this.post(`${API_URL}/Equipments/revisions/getByMonth` , centersAndData);
  }
  getRevisionsDelayed(clientId: number[]): Promise<MaintenanceActionDTO[]>{
    return this.post(`${API_URL}/Equipments/revisions/getDelayed` , clientId);
  }
  getRevisionsByEquipment(clientId: number , equipmentId: number): Promise<MaintenanceRevisionConfigurationDTO[]>{
    return this.get(`${API_URL}/Equipments/${equipmentId}/revisionConfiguration?clientId=${clientId}`);
  }
  getIncidentsByCenters(clientId: number[]): Promise<MaintenanceActionDTO[]>{
    return this.post(`${API_URL}/Equipments/incidents/getByCenters` , clientId);
  }
  deleteRevisionAction(idAction: number, clientId: number): Promise<MaintenanceRevisionConfigurationDTO[]>{
    return this.get(`${API_URL}/Equipments/deleteRevisionAction?idAction=${idAction}&clientId=${clientId}`);
  }
  addRevisionsByEquipment(equipmentId: number, revisions: MaintenanceRevisionConfigurationDTO[] ): Promise<MaintenanceRevisionConfigurationDTO[]>{
    return this.post(`${API_URL}/Equipments/${equipmentId}/revisionConfiguration`, revisions);
  }
  deleteRevisionsByEquipment(configurationId: number, clientId: number, equipmentId: number): Promise<MaintenanceRevisionConfigurationDTO[]>{
    return this.delete(`${API_URL}/Equipments/revisions?clientId=${clientId}&equipmentId=${equipmentId}&revisionId=${configurationId}`);
  }
  getMaintenanceRevisionsByEquipment(clientId: number, equipmentId: number): Promise<MaintenanceActionDTO[]>{
    return this.get(`${API_URL}/Equipments/${equipmentId}/revisions?clientId=${clientId}`);
  }
  getMaintenanceIncidentByEquipment(clientId: number, equipmentId: number): Promise<MaintenanceActionDTO[]>{
    return this.get(`${API_URL}/Equipments/${equipmentId}/incidents?clientId=${clientId}`);
  }
  getEquipmentsByID(clientId: number , equipmentId: number): Promise<EquipmentDTO>{
    return this.get(`${API_URL}/Equipments/${equipmentId}?clientId=${clientId}`);
  }
  deleteEquipments(clientId: number , equipmentId: number){
    return this.delete(`${API_URL}/Equipments?clientId=${clientId}&equipmentId=${equipmentId}`);
  }
  getActionDetailRevision(actionId: number) : Promise<MaintenanceActionDTO>{
    return this.get(`${API_URL}/Equipments/revisions/${actionId}`);
  }
  saveRevision(action: MaintenanceActionDTO) : Promise<MaintenanceActionDTO>{
    return this.put(`${API_URL}/Equipments/revisions` , action);
  }
  saveIncident(action: MaintenanceActionDTO) : Promise<MaintenanceActionDTO>{
    return this.put(`${API_URL}/Equipments/incidents` , action);
  }
  getActionDetailIncident(actionId: number) : Promise<MaintenanceActionDTO>{
    return this.get(`${API_URL}/Equipments/incidents/${actionId}`);
  }
  getActionComments(equipmentId: number, actionId: number) : Promise<MaintenanceCommentDTO[]>{
    return this.get(`${API_URL}/Equipments/${equipmentId}/actions/${actionId}/comments`);
  }
  setEquipments(Equipment: EquipmentDTO): Promise<any>{
    return this.post(`${API_URL}/Equipment/setEquipment`, Equipment);
  }

  addEquipmentActionComment(equipmentId: number, actionId: number, comment: MaintenanceCommentDTO): Promise<any>{
    return this.post(`${API_URL}/equipments/${equipmentId}/actions/${actionId}/comments`, comment);
  }

  //#region Actions
  
  getActions(clientId: number): Promise<ActionDTO[]>{
    return this.get(`${API_URL}/actions?clientId=${clientId}`);
  }

  getAction(actionId: number): Promise<ActionDTO> {
    return this.get(`${API_URL}/actions/getById?actionId=${actionId}`);
  }

  createOrUpdateAction(clientId: number, action: ActionDTO) {
    return this.post(`${API_URL}/actions?clientId=${clientId}`, action);
  }

  //#endregion

  //#endregion
  //#region ProductFamilies

  getProductFamilies(clientId: number): Promise<any[]>{
    return this.get(`${API_URL}/${clientId}/productFamilies`);
  }

  //#endregion

  downloadFile(url: string, fileNamePrefix: string) {
    return new Promise((resolve, reject) => {
      this.http.get(API_URL + url, { responseType: 'blob'}).subscribe(
        (response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);

            const blob = new Blob(binaryData, { type: dataType });
            const url = window.URL.createObjectURL(blob);

            var downloadLink = document.createElement("a");

            downloadLink.href = url;
            downloadLink.download = `${fileNamePrefix}-${new Date().toISOString()}.xlsx`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            resolve(null)
        },
        (err) => {
          reject(err);
          Errors.showGenericError();
        }
      );

    });
  }
  downloadFilePost(url: string, body: any, fileNamePrefix: string) {
    return new Promise((resolve, reject) => {
      this.http.post(API_URL + url, body, { responseType: 'blob'}).subscribe(
        (response: any) => {
            let dataType = response.type;
            let binaryData = [];
            binaryData.push(response);

            const blob = new Blob(binaryData, { type: dataType });
            const url = window.URL.createObjectURL(blob);

            var downloadLink = document.createElement("a");

            downloadLink.href = url;
            downloadLink.download = `${fileNamePrefix}-${new Date().toISOString()}.xlsx`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            resolve(null)
        }
      );

    });
  }
}
