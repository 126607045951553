import { NgModule } from '@angular/core';
import { ImageUrlPipe } from './ImageUrl/image-url.pipe';
import { IsExpiredPipe } from './is-expired/is-expired.pipe';
import { ImportErrorLevelToSeverity } from './importErrorLevelToSeverity/importErrorLevelToSeverity.pipe';
import {TimeUnitPipe} from "./timeUnit/time-unit.pipe";

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    ImageUrlPipe,
    IsExpiredPipe,
    ImportErrorLevelToSeverity,
    TimeUnitPipe
  ],
  exports: [
    ImageUrlPipe,
    IsExpiredPipe,
    ImportErrorLevelToSeverity,
    TimeUnitPipe
  ]
})
export class ApplicationPipesModule {}
