import { TokenService } from './token.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, tap } from 'rxjs';
import { API_URL } from 'src/environments/environment';
import { AuthResponseDTO } from '../DTO/AuthResponseDTO';
import { LoginDTO } from '../DTO/LoginDTO';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authenticationState = new BehaviorSubject<boolean | null>(null);
  private authSubject: BehaviorSubject<AuthResponseDTO | null>;

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) {

    this.authSubject = new BehaviorSubject<AuthResponseDTO | null>(null);

    this.authSubject.subscribe(res => {
      if (res) {
        this.tokenService.setToken(TokenService.TokenType.JwtToken, res.jwt);

        if (res.refreshToken) {
          this.tokenService.setToken(TokenService.TokenType.JwtRefreshToken, res.refreshToken);
        }

        //this.authenticationState.next(true);
        this.startRefreshTokenTimer();
      } else {
        //this.authenticationState.next(false);
      }
    });


  }

  public recoverPassword(userName: string) {

    const params = new HttpParams()
      .set('userName', userName);

    return this.http.get<AuthResponseDTO>(`${API_URL}/users/recover-password?`+ params.toString())
  }

  private refreshTokenTimeout: any;

  private startRefreshTokenTimer() {
    this.stopRefreshTokenTimer();
    // parse json object from base64 encoded jwt token
    const jwtToken = (this.authSubject.value) ? JSON.parse(atob(this.authSubject.value.jwt.split('.')[1])) : null;

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    //console.log('Token Expires', expires);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken(), timeout);
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeout) clearTimeout(this.refreshTokenTimeout);
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  async checkToken() {
    let tokenValid = this.tokenService.isTokenValid();
    if(tokenValid){
      this.authenticationState.next(true);
    }else{
      this.refreshToken();
    }
  }


  async refreshToken(refreshToken?: string | null) {

    if (!refreshToken) {
      refreshToken = this.tokenService.getToken(TokenService.TokenType.JwtRefreshToken);
    }

    if(refreshToken){
      this.http.post<AuthResponseDTO>(`${API_URL}/auth/refresh-token`, { token: refreshToken })
      .subscribe({
        next: (res: AuthResponseDTO) => {
          this.authSubject.next(res);
          this.authenticationState.next(true);
        },
        error: (err) => {
          this.authSubject.next(null);
          this.authenticationState.next(false);
        }
      });
    }else{
      this.authSubject.next(null);
      this.authenticationState.next(false);
    }
  }

  login(model: LoginDTO) {
    return this.http.post<AuthResponseDTO>(`${API_URL}/auth/login`, model)
        .pipe(
          map((res: AuthResponseDTO) => {
            this.authSubject.next(res);
            this.authenticationState.next(true);
            return res;
          }),
          tap({
            error: async (err) => {
              this.authSubject.next(null);
              this.authenticationState.next(false);
            }
          })
        );
  }

  async logout() {
    const token = await this.tokenService.getToken(TokenService.TokenType.JwtRefreshToken)
    if(token) this.http.post<any>(`${API_URL}/auth/revoke-token`, { token }).subscribe();

    this.stopRefreshTokenTimer();

    this.tokenService.removeToken(TokenService.TokenType.JwtToken);
    this.tokenService.removeToken(TokenService.TokenType.JwtRefreshToken);

    this.authSubject.next(null);
    this.authenticationState.next(false);
  }
}
