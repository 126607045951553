import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-hidden-pin',
  templateUrl: './hidden-pin.component.html',
  styleUrls: ['./hidden-pin.component.scss']
})
export class HiddenPinComponent implements OnInit {

  @Input()
  public pin: string;

  @Input()
  public show = false;

  @Input()
  public resetButton = false;

  @Output() 
  onReset = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  togglePinShow(event: Event){
    event.stopPropagation();
    this.show = !this.show;
  }

  resetPin(){
    this.onReset.emit(true);
  }

}
