import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'isExpired'
})
export class IsExpiredPipe implements PipeTransform {

  transform(value: string, thresholdHours?: number): boolean {

    let datetime = DateTime.fromISO(value);
    
    if(!datetime.isValid){
      return false;
    }

    if (thresholdHours) {
      return datetime < DateTime.now().plus({ hours: thresholdHours });
    }

    return datetime < DateTime.now();
  }

}
