import { NgModel } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-ng-model-validator',
  templateUrl: './ng-model-validator.component.html',
  styleUrls: ['./ng-model-validator.component.scss']
})
export class NgModelValidatorComponent implements OnInit {

  @Input()
  model: NgModel;

  @Input()
  maxlength?: number;

  @Input()
  minlength?: number;

  constructor() { }

  ngOnInit(): void {
    
  }

}
