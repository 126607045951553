<div class="mt-1">
    <div *ngIf="maxlength" class="chars-counter">{{model.value? model.value?.length : 0}} / {{maxlength}}</div>

    <div *ngIf="model.invalid && (model.dirty || model.touched)" class="form-error text-danger">
        <div *ngIf="model.errors && model.errors['required']">
            {{ 'Required Field' | translate }}
        </div>
        <div *ngIf="model.errors && model.errors['maxlength']">
            {{ 'MAX_LENGTH' | translate: { chars: model.errors['maxlength'].requiredLength } }}
        </div>
        <div *ngIf="model.errors && model.errors['minlength']">
            {{ 'MIN_LENGTH' | translate: { chars: model.errors['minlength'].requiredLength } }}
        </div>
        <div *ngIf="model.errors && model.errors['pattern']">
            {{ 'INCORRECT_FORMAT' | translate }}
        </div>
    </div>
</div>