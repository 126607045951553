import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'importErrorLevelToSeverity'})
export class ImportErrorLevelToSeverity implements PipeTransform {
  transform(value: string): string {
    switch (value) {
        case 'E': return 'error';
        case 'W': return 'warn';
        case 'I': return 'info';
    }
    
    return 'error';
  }
}