import { LabelDTO } from './../../../../DTO/LabelDTO';
import { ProductStockDTO } from 'src/app/DTO/ProductStockDTO';
import { TranslateService } from '@ngx-translate/core';
import { CenterDTO } from './../../../../DTO/CenterDTO';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-center-detail',
  templateUrl: './stock-detail.component.html',
  styleUrls: ['./stock-detail.component.scss']
})
export class StockDetailComponent implements OnInit {

  @Input()
  public id: number;

  public model: ProductStockDTO;

  public labels: LabelDTO[];

  public isModalLoading: boolean;
  
  constructor(
    private api: ApiService,
    public activeModal: NgbActiveModal,
    private translate: TranslateService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.api.getStockById(this.id).then((res: ProductStockDTO) => {
      this.model = res;

      this.api.getLabelsByStockUuid(res.uuid).then((labels: LabelDTO[]) => {
        this.labels =  labels;
      });
    }, 
    (err) => {
      
    });
  }

  dismiss(reason?: any) {
    this.activeModal.dismiss(reason);
  }

  openStock(product: ProductStockDTO){
    const modalRef = this.modalService.open(StockDetailComponent); 
    modalRef.componentInstance.id = product.id;

    modalRef.result.then((result) => {
      
    }, (reason) => {
      console.log(reason);
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

}
