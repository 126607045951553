<div class="modal-content">
    <div class="modal-header">
        <h4 class="modal-title text-gray m-0 font-w-400 text-xs text-dark">
            <span>Stock</span>
        </h4>
        <button class="btn btn-close" (click)="dismiss('Cross click')"></button>
    </div>
    
    <div class="modal-body sk-chase-wrapper" *ngIf="!model">
        <div class="sk-chase">
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
            <div class="sk-chase-dot"></div>
        </div>
    </div>

    <div class="modal-body" >
        <div *ngIf="model">
            <div class="row">
                <div class="col-6">
                 <div>
                    <h2>{{model.productName}}</h2>

                    <table>
                        <tr>
                            <td></td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td>Lote:</td>
                            <td>{{model.lote}}</td>
                        </tr>
                        <tr>
                            <td>Primary Exp:</td>
                            <td>{{model.expirationDatePrimary | date :'dd/MM/yyyy'}}</td>
                        </tr>
                        <tr>
                            <td>Secondary Exp:</td>
                            <td> <div [class.expirated]="model.expirationDateSecondary && (model.expirationDateSecondary | isExpired:48)">
                                <b *ngIf="model.expirationDateSecondary">{{model.expirationDateSecondary | date :'dd/MM/yyyy HH:mm'}}</b>
                                <span *ngIf="!model.expirationDateSecondary">-</span>
                              </div></td>
                        </tr>
                        <tr>
                            <td>{{ 'Status' | translate }}:</td>
                            <td><span [style.color]="model.lastLabelActionColor">{{model.lastLabelActionName}}</span></td>
                        </tr>

                    </table>

                    <ng-container *ngIf="model.parentProductStocks.length > 0">
                        <h3 class="mt-3 mb-0">Materias primas:</h3>
                        <ul>
                            <li *ngFor="let s of model.parentProductStocks"><a href="javascript:void(0)" (click)="openStock(s)">{{s.productRef}} - {{s.productName}} (Lote: {{s.lote}})</a></li>
                        </ul>
                    </ng-container>

                    <ng-container *ngIf="model.childProductStocks.length > 0">
                        <h3 class="mt-3 mb-0">Productos subyacentes:</h3>
                        <ul>
                            <li *ngFor="let s of model.childProductStocks"><a href="javascript:void(0)" (click)="openStock(s)">{{s.productRef}} - {{s.productName}} (Lote: {{s.lote}})</a></li>
                        </ul>
                    </ng-container>
                 </div>
                </div>
                <div class="col-6 bg-gray">
                    <div>
                        <h2>Etiquetas</h2>

                        <section class="cd-container" *ngIf="labels">
		
                        <div class="cd-timeline-block" *ngFor="let l of labels" [style.--color]="l.actionColor">
                                <div class="cd-timeline-img">
                                    
                                </div>
                    
                                <div class="cd-timeline-content">
                                    <h2>{{l.actionName}}</h2>
                                    <div class="cd-date">{{l.printDate | date :'dd/MM/yyyy HH:mm'}} - {{l.printUserName}}</div>
                                    <div class="label-body">
                                    <div>{{ 'Lote' | translate }}: {{l.productStockLote}}</div>
                                    <div>{{ 'Primary expiration' | translate }}: {{l.expirationDatePrimary | date :'dd/MM/yyyy HH:mm'}}</div>
                                    <div>{{ 'Secondary expiration' | translate }}: {{l.expirationDateSecondary | date :'dd/MM/yyyy HH:mm'}}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>