import { Pipe, PipeTransform } from '@angular/core';
import { IMG_HOST } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

  transform(value: string | undefined, mode?: string): string | undefined {
    if (value) {
      if (!value.startsWith('http')) {
        value = IMG_HOST + value;
      }

      if (mode) {
        const rest = value.substring(0, value.lastIndexOf('/') + 1);
        const last = value.substring(value.lastIndexOf('/'), value.length);

        value = rest + mode + last;
      }
    }
    return value;
  }

}
