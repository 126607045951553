<div class="px-5 py-4">
    <h1 class="mb-4"><mat-icon>inventory_2</mat-icon> {{ 'stocks' | translate }}</h1>
    
    <div class="filters-block mat-elevation-z2">

        <span class="p-float-label">
            <input pInputText id="product" [(ngModel)]="filters.product" />
            <label htmlFor="product">Producto (Ref./Nombre)</label>
        </span>

        <span class="p-float-label">
            <input pInputText id="product" [(ngModel)]="filters.lote" />
            <label htmlFor="lote">Lote</label>
        </span>

        <span class="p-float-label">
            <input pInputText id="user" [(ngModel)]="filters.user" />
            <label htmlFor="user">Usuario</label>
        </span>

        <br><br>

        <mat-form-field appearance="fill">
            <mat-label>{{ 'Expiration' | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Desde" [(ngModel)]="filters.expirationFrom">
              <input matEndDate placeholder="Hasta" [(ngModel)]="filters.expirationTo">
            </mat-date-range-input>
            <mat-hint hidden>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
     
        <mat-form-field appearance="fill">
            <mat-label>Recepción/Etiquetado</mat-label>
            <mat-date-range-input [rangePicker]="picker2">
              <input matStartDate placeholder="Desde" [(ngModel)]="filters.receptionFrom">
              <input matEndDate placeholder="Hasta" [(ngModel)]="filters.receptionTo">
            </mat-date-range-input>
            <mat-hint hidden>DD/MM/YYYY - DD/MM/YYYY</mat-hint>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
        </mat-form-field>

        <br>
        
        <span class="p-float-label">
            <p-dropdown 
                [options]="filterBoolOptions" 
                [(ngModel)]="filters.consumed" 
                optionLabel="label" 
                optionValue="value"
                [showClear]="true" 
                placeholder="-"
            ></p-dropdown>
            <label for="float-label">{{ 'REPORTS.STOCKS.COLUMNS.Consumed' | translate }}</label>
        </span>

        <span class="p-float-label">
            <p-dropdown 
                [options]="filterBoolOptions" 
                [(ngModel)]="filters.elaborated" 
                optionLabel="label" 
                optionValue="value"
                [showClear]="true" 
                placeholder="-"
            ></p-dropdown>
            <label for="float-label">{{ 'REPORTS.STOCKS.COLUMNS.Elaborated' | translate }}</label>
        </span>

        <div>
            <span class="p-float-label">
                <p-multiSelect 
                    [(ngModel)]="filters.centerIds"
                    name="centers"
                    [options]="centers" 
                    optionLabel="name" 
                    optionValue="id"
                    [placeholder]="'Seleccionar centros'"
                    scrollHeight="500px"
                >
                    <ng-template let-item pTemplate="item">
                        <div>{{ item.code }} - {{ item.name }}</div>
                    </ng-template>
                    <ng-template let-item pTemplate="selectedItem">
                        <div>{{ item.code }} - {{ item.name }}</div>
                    </ng-template>
                </p-multiSelect>
                <label for="centers">{{'Centers' | translate}}</label>
            </span>
            
           
        </div>
        <br>
        <button mat-flat-button color="primary" class="search" (click)="refreshData()"><mat-icon>search</mat-icon> Buscar</button>
    </div>

    <div class="top-buttons mb-4 clearfix">
        <div class="float-end">
            <button mat-flat-button color="accent" (click)="exportXLS()" [disabled]="exportLoading">
                <p class="m-0 p-0">
                    <mat-spinner *ngIf="exportLoading" diameter="20"></mat-spinner>
                    <i class="fa-solid fa-cloud-arrow-down me-1" *ngIf="!exportLoading"></i> 
                    {{ 'Export' | translate }} XLS
                </p>
            </button>
        </div>
    </div>

    <section class="mt-4">
        
        <div class="sk-chase-wrapper" *ngIf="isLoading">
            <div class="sk-chase">
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
                <div class="sk-chase-dot"></div>
            </div>
        </div>

        <div class="mat-elevation-z2" [hidden]="isLoading || !firstSearchDone">
            <table mat-table [dataSource]="dataSource" matSort>
        
                <ng-container matColumnDef="center">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Centers' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.centerName}} </td>
                </ng-container>

                <ng-container matColumnDef="lote">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Lote' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.lote}} </td>
                </ng-container>
            
                <ng-container matColumnDef="productName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Product' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Estado' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> <span [style.color]="element.lastLabelActionColor">{{element.lastLabelActionName}}</span> </td>
                </ng-container>
            
                <ng-container matColumnDef="expirationDatePrimary">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.ExpirationPrimary' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> <app-expiration-date [date]="element.expirationDatePrimary"></app-expiration-date> </td>
                </ng-container>
            
                <ng-container matColumnDef="expirationDateSecondary">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.ExpirationSecondary' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element"> <app-expiration-date [date]="element.expirationDateSecondary"></app-expiration-date> </td>
                </ng-container>
            
                <ng-container matColumnDef="consumed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Consumed' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> <span [class.consumed]="element.consumed">{{(element.consumed ? 'YES' : '') | translate }}</span> </td>
                </ng-container>
            
                <ng-container matColumnDef="labelsCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'REPORTS.STOCKS.COLUMNS.Labels' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center"> {{element.labelsCount}} </td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openStock(row)"></tr>
    
            </table>
    
            <mat-paginator 
                pageSize="20" 
                [pageSizeOptions]="[10, 20, 50, 100]"
                showFirstLastButtons>
            </mat-paginator>
        </div>
        
    </section>
</div>